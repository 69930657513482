import * as React from "react";
import FullWidthVideo from "../components/Video/FullWidthVideo";
import GojiraLogo from "../components/Logo/GojiraLogo";
import Ticker from "../components/Ticker/Ticker";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import PFPTicker from "../components/svg/PFPTicker";
import ShopTicker from "../components/svg/ShopTicker";
import ANFTLogo from "../components/svg/ANFTIcon copy";
import HomepageShop from "../components/Home/Carousel/Shop/Shop";
import { getDeviceType } from "../components/Utils/DeviceType";
import GamesCarousel from "../components/Carousel/Games/Games";
import { IsMobile } from "../components/Utils/IsMobile";

const IndexPage = () => {
  gsap.registerPlugin(ScrollTrigger);

  const scrollContainer = useRef(null);
  const [heroVideo, setHeroVideo] = React.useState(null);
  const [heroPoster, setHeroPoster] = React.useState(null);

  useEffect(() => {
    const heroVideoSizes = {
      mobile: "/videos/Gojira_home_mobile.mp4",
      tablet: "/videos/Gojira_home_sm.mp4",
      desktop: "/videos/Gojira_home_md.mp4",
      largeDesktop: "/videos/Gojira_home_lg.mp4",
    };

    const heroVideoPosters = {
      mobile: "/videos/Gojira_home_mobile_poster.jpg",
      tablet: "/videos/Gojira_home_sm_poster.jpg",
      desktop: "/videos/Gojira_home_md_poster.jpg",
      largeDesktop: "/videos/Gojira_home_lg_poster.jpg",
    };

    function updateVideo() {
      setHeroVideo(heroVideoSizes[getDeviceType()]);
      setHeroPoster(heroVideoPosters[getDeviceType()]);
    }

    updateVideo();
    window.addEventListener("resize", updateVideo);

    return () => {
      window.removeEventListener("resize", updateVideo);
    };
  }, []);

  return (
    <>
      <div
        className={`relative w-full block z-0 min-h-[177.777777777777778vw] md:min-h-[66.6666667vw]`}
      >
        <h1 className={`sr-only`}>Gojira Labs – We make monsters</h1>
        {heroVideo && heroPoster && (
          <FullWidthVideo
            mp4={heroVideo}
            poster={heroPoster}
            className={`bg-black fixed top-0 left-0`}
          />
        )}
        <div
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:hidden`}
        >
          <GojiraLogo isRotated />
        </div>
      </div>
      <div className="z-10 relative">
        <Ticker
          endRef={scrollContainer}
          direction={"ltr"}
          duration={50}
          className="bg-green-dark py-1 md:py-2.5"
          endTrigger={IsMobile() ? "+=80" : "+=220"}
        >
          <PFPTicker />
        </Ticker>
        <div
          id="about-gojira"
          name="about-gojira"
          className={`bg-green text-black px-12 py-28 lg:pt-64 lg:pb-44`}
        >
          <p
            className={`p-intro font-inter block mx-auto mb-9 lg:mb-14`}
            style={{ maxWidth: 840 }}
          >
            Welcome to Gojira Labs. We’re the mad scientists breathing new life
            into the stale world of digital entertainment. We’ve stitched
            together the best bits of gaming, tech and crypto, to create a
            brand-new gaming experience that puts players in control. We have
            fun. We blow stuff up. We make monsters. We hate the cynical
            monetization strategies that have plagued Web3 games just as much as
            you do, so we decided to do things differently. Forget pay-to-play
            and play-to-earn: this is play-for-fun.
            <br />
            <br />
            Introducing our latest monster – the animatronic NFT (aNFT), a new
            framework that connects PFP communities to games that are simply
            super freaking fun to play. Try it out now, and follow us for
            updates from the lab.
          </p>
          <PrimaryButton
            color={"red"}
            hover={"black"}
            url={"mailto:inquiries@gojiralabs.com"}
            className={`mx-auto table`}
          >
            Get in touch
          </PrimaryButton>
        </div>
        <GamesCarousel />
        {/* aNFT row */}
        <div
          className={`bg-grey text-white px-12 pt-16 pb-16 md:py-40 relative z-30`}
        >
          <div
            className="md:flex md:flex-row-reverse md:items-center mx-auto"
            style={{ maxWidth: 1000 }}
          >
            <div className={`w-full md:w-1/2 block`}>
              <h2 className="table mx-auto mb-10 md:ml-0 md:mb-11">
                <ANFTLogo />
                <span className={`sr-only`}>aNFT</span>
              </h2>
              <p className={`p mb-10`}>
                Say hello to the animatronic NFT (aNFT), a new type of digital
                collectible that will soon let you spawn playable characters
                from static PFP collections. We blend the fun of gaming with the
                power of Web3, to create new experiences for players to race,
                fight and battle against one another. Will you emerge
                victorious?
              </p>
              <PrimaryButton
                url={`/animatronic-nfts`}
                className={`table mx-auto md:ml-0`}
                color={"red"}
                hover={"green"}
              >
                Learn more <span className={`sr-only`}>about aNFTs</span>
              </PrimaryButton>
            </div>
            <div className={`w-full md:w-1/2 block mr-10`}>
              <FullWidthVideo
                mp4={"videos/anft-homepage-module.mp4"}
                className={``}
                poster={"videos/anft-homepage-module.jpg"}
              />
            </div>
          </div>
        </div>
        <Ticker
          endRef={scrollContainer}
          direction={"rtl"}
          className={`bg-red-light py-5`}
          duration={36.666666666666667}
        >
          <ShopTicker color={"#ffffff"} />
        </Ticker>
        <HomepageShop />
      </div>
    </>
  );
};

export default IndexPage;
