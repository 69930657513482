import React from "react";
import FullWidthVideo from "../../Video/FullWidthVideo";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useRef, useEffect } from "react";
import Slider from "react-slick";
import { getDeviceType } from "../../Utils/DeviceType";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  swipeToSlide: true,
};

const GamesMobile = ({ games }) => {
  return (
    <Slider className={`w-full pb-20`} {...settings}>
      {games.map((slide, index) => {
        return (
          <CarouselSlide
            eyebrows={slide.eyebrows}
            logo={slide.logo}
            name={slide.name}
            description={slide.description}
            button={slide.button}
            video={slide.video}
            poster={slide.poster}
            key={index}
            comingSoon={slide.comingSoon}
            comingSoonPoster={slide.comingSoonPoster}
          />
        );
      })}
    </Slider>
  );
};

type CarouselSlideProps = {
  eyebrows: string[];
  logo: string;
  name: string;
  description: string;
  button?: {
    text: string;
    url: string;
  };
  video: {
    mobile: string;
    tablet: string;
    desktop: string;
    largeDesktop: string;
  };
  poster: {
    mobile: string;
    tablet: string;
    desktop: string;
    largeDesktop: string;
  };
  comingSoon?: boolean;
  comingSoonPoster?: {
    mobile: string;
    desktop: string;
  };
};

const CarouselSlide = ({
  eyebrows,
  logo,
  name,
  description,
  button,
  video,
  poster,
  comingSoon,
  comingSoonPoster,
}: CarouselSlideProps) => {
  const [thisVideo, setThisVideo] = React.useState(null);
  const [thisPoster, setThisPoster] = React.useState(null);

  useEffect(() => {
    const updateVideo = () => {
      setThisVideo(video[getDeviceType()]);
      setThisPoster(poster[getDeviceType()]);
    };

    updateVideo();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", updateVideo);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", updateVideo);
      }
    };
  }, []);
  const containerRef = useRef(null);

  return (
    <div
      ref={containerRef}
      className={`panel relative bg-black text-white pt-20`}
    >
      <div className={`w-full`}>
        <div className={`flex flex-wrap flex-row justify-center`}>
          {eyebrows.map((eyebrow, index) => {
            return <Eyebrow key={index} eyebrow={eyebrow} />;
          })}
        </div>
        {button && button.url ? (
          <a
            href={button.url}
            className={`table mx-auto`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              alt={`${name} Logo`}
              className={`block mx-auto max-h-24 h-auto max-w-full w-auto mb-5 mt-1`}
            />
          </a>
        ) : (
          <img
            src={logo}
            alt={`${name} Logo`}
            className={`block mx-auto max-h-24 h-auto max-w-full w-auto mb-5 mt-1`}
          />
        )}

        {comingSoon ? (
          <div className={`mb-9 relative`}>
            <div className={`w-full h-auto`}>
              {comingSoonPoster && comingSoonPoster.desktop && (
                <img
                  src={comingSoonPoster.mobile}
                  className={`bg-black object-cover object-center h-full w-full`}
                />
              )}

              <img
                src={`/images/logos/COMING_SOON.svg`}
                alt={`Coming Soon`}
                className={`mb-9 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/4 max-w-[300px] h-auto`}
              />
            </div>
          </div>
        ) : (
          <>
            {thisVideo && thisPoster && (
              <>
                {button && button.url ? (
                  <a
                    href={button.url}
                    className={`block relative mb-9 `}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <FullWidthVideo
                      mp4={thisVideo}
                      className={`bg-black relative block`}
                      poster={thisPoster}
                    />
                  </a>
                ) : (
                  <FullWidthVideo
                    mp4={thisVideo}
                    className={`bg-black mb-9 relative block`}
                    poster={thisPoster}
                  />
                )}
              </>
            )}
          </>
        )}

        <p className={`p px-9 mb-11 relative block`}>{description}</p>
        {button && (
          <>
            {button.url ? (
              <PrimaryButton
                url={button.url}
                color={"red"}
                hover={"white"}
                className={`table mx-auto`}
              >
                {button.text}
              </PrimaryButton>
            ) : (
              <div
                className={`text-red-light table mx-auto btn-primary font-mdio font-medium text-lg uppercase py-1.5`}
              >
                {button.text}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const Eyebrow = ({ eyebrow }: { eyebrow: string }) => {
  const specialEyebrows = {
    "aNFT Arcade": (
      <>
        <span className="lowercase">a</span>NFT Arcade
      </>
    ),
  };

  return (
    <div className="flex-grow-0 flex-shrink-0 text-xs md:text-sm font-normal font-mdio text-white uppercase border-2 border-red-light pt-1.5 pb-1 px-4 inline-table mx-2 mb-4 text-center">
      {specialEyebrows[eyebrow] ? specialEyebrows[eyebrow] : eyebrow}
    </div>
  );
};

export default GamesMobile;