import React from "react";
import ShopCard from "../../../ShopCard/ShopCard";
import Slider from "react-slick";
import productData from "../../../../data/products.json";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  swipeToSlide: true,
};

const HomepageShop = () => {
  const productGroups = groupByFourStrict(productData);

  return (
    <div className={`bg-red-light text-white md:pb-20 relative z-30`}>
      <Slider className={`w-full`} {...settings}>
        {productGroups.map((group, index) => {
          return (
            <div key={index}>
              <div className={`grid grid-cols-2 gap-1 md:gap-2 lg:grid-cols-4`}>
                {group.map((product, index) => {
                  return (
                    <a href={`/shop`} className="bg-white" key={index}>
                      <ShopCard
                        name={product.name}
                        price={product.price}
                        image={product.image}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default HomepageShop;

// function that takes an array of objects and returns those objects in groupings of 4 and only groupings of four
const groupByFourStrict = (array) => {
  const groups = [];
  let i;
  for (i = 0; i < array.length; i += 4) {
    if (array.slice(i, i + 4).length === 4) {
      groups.push(array.slice(i, i + 4));
    }
  }
  return groups;
};
