// function that returns mobile, tablet, desktop, or large desktop
export const IsMobile = () => {
  if (typeof window !== "undefined") {
    const width = window.innerWidth;
    if (width < 1024) {
      return true;
    } else {
      return false;
    }
  }
};
