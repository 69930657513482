import React from "react";
import { getDeviceType } from "../../Utils/DeviceType";
import GamesDesktop from "./GamesDesktop";
import GamesMobile from "./GamesMobile";
import { useState } from "react";

const games = [
  {
    eyebrows: ["Animatronic NFT", "PFP-Powered"],
    logo: "/images/logos/SPRINFT.png",
    name: "Sprinft",
    description:
      "Sprinft will be the first game from Gojira Labs to use animatronic NFTs (aNFTs) to bring rival characters from different PFP projects together in a fiercely fun contest. With players pitting their avatars against each other in a race to the finish, who will claim the glory, and who will be left eating dust? Play Sprinft and unlock a PFP character to find out. Just don’t ask us how to pronounce it.",
    button: {
      text: "Play",
      url: "https://sprinft.gojiralabs.com",
    },
    video: {
      mobile: "/videos/GOJIRA_SPRINFT_16X9_sm.mp4",
      tablet: "/videos/GOJIRA_SPRINFT_1X1.mp4",
      desktop: "/videos/GOJIRA_SPRINFT_1X1.mp4",
      largeDesktop: "/videos/GOJIRA_SPRINFT_1X1.mp4",
    },
    poster: {
      mobile: "/videos/GOJIRA_SPRINFT_16X9_sm_poster.jpg",
      tablet: "/videos/GOJIRA_SPRINFT_1X1_poster.jpg",
      desktop: "/videos/GOJIRA_SPRINFT_1X1_poster.jpg",
      largeDesktop: "/videos/GOJIRA_SPRINFT_1X1_poster.jpg",
    },
    comingSoon: false,
  },
  {
    eyebrows: ["aNFT Arcade", "Under Development"],
    logo: "/images/logos/blockxer-fight.png",
    name: "Blockxer Fight",
    description:
      "In this upcoming Street Fighter-inspired button-basher, players will pit their aNFTs against one another to see who will claim the bragging rights. Users will spawn aNFTs from compatible NFT collections and unlock the fun. Let the melee ensue.",
    button: {
      text: "Coming Soon!",
      url: null,
    },
    video: {
      mobile: "/videos/GOJIRA_BLOCKXER_16X9_sm.mp4",
      tablet: "/videos/GOJIRA_BLOCKXER_1X1.mp4",
      desktop: "/videos/GOJIRA_BLOCKXER_1X1.mp4",
      largeDesktop: "/videos/GOJIRA_BLOCKXER_1X1.mp4",
    },
    poster: {
      mobile: "/videos/GOJIRA_BLOCKXER_16X9_sm_poster.jpg",
      tablet: "/videos/GOJIRA_BLOCKXER_1X1_poster.jpg",
      desktop: "/videos/GOJIRA_BLOCKXER_1X1_poster.jpg",
      largeDesktop: "/videos/GOJIRA_BLOCKXER_1X1_poster.jpg",
    },
    comingSoon: true,
    comingSoonPoster: {
      mobile: "/images/blockxer-fight/BlockxerFIght_16x9.png",
      desktop: "/images/blockxer-fight/BlockxerFIght_1x1.png",
    },
  },
  {
    eyebrows: ["NFT-Powered", "Web3 Arcade"],
    logo: "/images/logos/blockxer.png",
    name: "Blockxer",
    description:
      "Blockxer invites gamers to take on villains of the crypto space in this meme-infused 2D beat ’em up arcade game. In this addictive side-scroller, players access the game using retro-style ‘cartridges’ in the form of NFTs. In the full release of the game, the implementation of NFTs will go even further. Vital elements of the game will be made available as NFT assets, allowing virtually unlimited opportunities for customization.",
    button: {
      text: "Play",
      url: "https://blockxer.com/",
    },
    video: {
      mobile: "/videos/GOJIRA_BLOCKXER_16X9_sm.mp4",
      tablet: "/videos/GOJIRA_BLOCKXER_1X1.mp4",
      desktop: "/videos/GOJIRA_BLOCKXER_1X1.mp4",
      largeDesktop: "/videos/GOJIRA_BLOCKXER_1X1.mp4",
    },
    poster: {
      mobile: "/videos/GOJIRA_BLOCKXER_16X9_sm_poster.jpg",
      tablet: "/videos/GOJIRA_BLOCKXER_1X1_poster.jpg",
      desktop: "/videos/GOJIRA_BLOCKXER_1X1_poster.jpg",
      largeDesktop: "/videos/GOJIRA_BLOCKXER_1X1_poster.jpg",
    },
    comingSoon: false,
  },
];

const GamesCarousel = () => {
  return (
    <div
      id="games"
      className={`md:min-w-screen md:min-h-screen block relative bg-black text-white z-30`}
    >
      <div className="block md:hidden">
        <GamesMobile games={games}></GamesMobile>
      </div>
      <div className="hidden md:block">
        <GamesDesktop games={games}></GamesDesktop>
      </div>
    </div>
  );
};

export default GamesCarousel;
